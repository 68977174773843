import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useSelect } from 'react-supabase'
import { Layout } from 'src/components/organisms/layouts'
import { List } from 'src/types/entities'

export default function MyListWithoutAuthentication() {
  const [result] = useSelect<List>('lists', {
    columns: `*, tasks(*)`
  })

  return (
    <Layout>
      <Container maxWidth="sm">
        <Box sx={{ my: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            My tasks
          </Typography>

          <ul>
            {result.fetching ? (
              <>Loading...</>
            ) : (
              result.data?.map((list, key) => {
                return (
                  <li key={key}>
                    <Box>
                      <strong>List: {list.id}</strong>
                    </Box>
                    <Box ml={2}>
                      Tâches liées
                      <ul>
                        {list.tasks?.map((task) => {
                          return <li key={task.id}>{task.name}</li>
                        })}
                      </ul>
                    </Box>
                  </li>
                )
              })
            )}
          </ul>
        </Box>
      </Container>
    </Layout>
  )
}
